export enum ExternalLinksType {
  MANUAL,
  STABLES ,
  ABOUT_US ,
  CONTACT_US ,
  PARTNER_POKERPOPUP,
  PARTNER_POKERSTRATEGY,
  PARTNER_SMARTEV,
  BECOME_PARTNER,
  POST_PACKAGE,
  FEEDBACK,
  SHARING,
  MANUAL_IMPORT,
  PAYMENT_INFO,
  PARTNER_SIMPLEPOKER,
  PRO,
  CRYPTO_INSTRUCTION,
  SURVEY,
  PARTNER_HAND2NOTE,
  JOIN_OUR_TEAM,
  CONFIG_FBR,
  CONFIG_HRC,
  MANUAL_ROUND_FREQ,
}
