import {Pipe, PipeTransform} from '@angular/core';
import {ExternalLinksType} from '../enum/external-links.enum';
import {TFbrLang} from '../enum/lang.enum';



const LINKS = {
  [ExternalLinksType.MANUAL] : {
    [TFbrLang.en]: 'https://help.freebetrange.com/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/',
    [TFbrLang.it]: 'https://help.freebetrange.com/it/',
    [TFbrLang.pt]: 'https://help.freebetrange.com/pt/',
  },
  [ExternalLinksType.STABLES]: {
    [TFbrLang.en]: 'https://stables.freebetrange.com/',
    [TFbrLang.ru]: 'https://stables.freebetrange.com/ru/',
    [TFbrLang.es]: 'https://stables.freebetrange.com/es/',
    [TFbrLang.pt]: 'https://stables.freebetrange.com/pt/',
  },
  [ExternalLinksType.ABOUT_US]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/AboutUs/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/AboutUs/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/AboutUs/'
  },
  [ExternalLinksType.CONTACT_US]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/ContactUs/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/ContactUs/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/ContactUs/'
  },
  [ExternalLinksType.PARTNER_POKERPOPUP]: {
    [TFbrLang.en]: 'https://pokerpopup.net/?tracking=9156',
    [TFbrLang.ru]: 'https://pokerpopup.ru/?tracking=9156'
  },
  [ExternalLinksType.BECOME_PARTNER]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/Partners/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/Partners/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/Partners/'
  },
  [ExternalLinksType.PARTNER_POKERSTRATEGY]: {
    [TFbrLang.en]: 'https://www.pokerstrategy.com/forum/thread.php?threadid=509811',
    [TFbrLang.ru]: 'https://ru.pokerstrategy.com/forum/thread.php?threadid=1435508',
    [TFbrLang.es]: 'https://es.pokerstrategy.com/forum/thread.php?postid=3062407'
  },
  [ExternalLinksType.POST_PACKAGE]: {
    [TFbrLang.en]: 'https://form.typeform.com/to/aQaGAoZx',
    [TFbrLang.ru]: 'https://form.typeform.com/to/KKPSQhKV'
  },
  [ExternalLinksType.FEEDBACK]: {
    [TFbrLang.en]: 'https://nickjamespoker.typeform.com/to/ZirxpNht',
    [TFbrLang.ru]: 'https://nickjamespoker.typeform.com/to/H73hjS5i'
  },
  [ExternalLinksType.PARTNER_SMARTEV]: {
    [TFbrLang.en]: 'https://poker-smartev.com/en/',
    [TFbrLang.es]: 'https://poker-smartev.com/'
  },
  [ExternalLinksType.SHARING]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/Sharing/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/Sharing/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/Sharing/'
  },
  [ExternalLinksType.MANUAL_IMPORT]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/Import/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/Import/'
  },
  [ExternalLinksType.PAYMENT_INFO]: {
    [TFbrLang.en]: 'https://freebetrange.notion.site/Freebetrange-Payment-Details-06415ebcc2544e0496362ad20c157591',
    [TFbrLang.ru]: 'https://freebetrange.notion.site/Freebetrange-c3c62cbfadaa416b83f084f9cddc77a0',
    [TFbrLang.es]: 'https://freebetrange.notion.site/Freebetrange-Detalles-del-pago-ae91e590245f431b8ddbbfb18976bd5d'
  },
  [ExternalLinksType.PARTNER_SIMPLEPOKER]: {
    [TFbrLang.en]: 'https://simplepoker.com/en/',
    [TFbrLang.ru]: 'https://simplepoker.com/'
  },
  [ExternalLinksType.PRO]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/pro/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/PRO/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/PRO/'
  },
  [ExternalLinksType.CRYPTO_INSTRUCTION]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/CryptoPayment/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/CryptoPayment/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/CryptoPayment/',
    [TFbrLang.pt]: 'https://help.freebetrange.com/pt/CryptoPayment/'
  },
  [ExternalLinksType.SURVEY]: {
    [TFbrLang.en]: 'https://form.typeform.com/to/FjbAY8mO',
    [TFbrLang.ru]: 'https://form.typeform.com/to/KWnzHUCB'
  },
  [ExternalLinksType.PARTNER_HAND2NOTE]: {
    [TFbrLang.en]: 'https://hand2noteguide.com/the-best-poker-preflop-charts-software/',
    [TFbrLang.ru]: 'https://hand2noteguide.com/ru/the-best-poker-preflop-charts-software/',
    [TFbrLang.pt]: 'https://hand2noteguide.com/pt/the-best-poker-preflop-charts-software/'
  },
  [ExternalLinksType.JOIN_OUR_TEAM]: {
    [TFbrLang.en]: 'https://blog.freebetrange.com/join-our-team',
    [TFbrLang.ru]: 'https://blog.freebetrange.com/join-our-team-ru'
  },
  [ExternalLinksType.CONFIG_FBR]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/Import-fbrange/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/Import-fbrange/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/Import-fbrange/',
    [TFbrLang.pt]: 'https://help.freebetrange.com/pt/Import-fbrange/'
  },
  [ExternalLinksType.CONFIG_HRC]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/Import-HRC/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/Import-HRC/',
    [TFbrLang.pt]: 'https://help.freebetrange.com/pt/Import-HRC/'
  },
  [ExternalLinksType.MANUAL_ROUND_FREQ]: {
    [TFbrLang.en]: 'https://help.freebetrange.com/RoundWeights/',
    [TFbrLang.ru]: 'https://help.freebetrange.com/ru/RoundWeights/',
    [TFbrLang.pt]: 'https://help.freebetrange.com/pt/RoundWeights/',
    [TFbrLang.es]: 'https://help.freebetrange.com/es/RoundWeights/',
    [TFbrLang.fr]: 'https://help.freebetrange.com/fr/RoundWeights/'

  }
};

@Pipe({
  name: 'localizedLinks'
})
export class LocalizedLinksPipe implements PipeTransform {
  defaultLanguage = TFbrLang.en;

  transform(locale: TFbrLang | string, externalLinkType: ExternalLinksType): unknown {
    const localizedLinkEntity = LINKS[externalLinkType];
    if (localizedLinkEntity) {
      const link = localizedLinkEntity[locale];
      return link || localizedLinkEntity[this.defaultLanguage];
    }
    return null;
  }

}
